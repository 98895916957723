import React, { Fragment, useState, useRef, useEffect } from 'react';
import { Modal, Container, Row, Col, Image, Carousel, Button } from 'react-bootstrap';
import logo from '../../img/logo_fondo_blanco.png';
import { getMultipleRandom } from '../../helpers/AppHelpers';
import PhotoAlbum from 'react-photo-album';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4' ;

const DashboardPage = () => {
    const [modalShow, setModalShow] = useState(false);
    const images = [1, 22, 25, 2727, 28];
    //const { isFetching } = useSelector(userSelector);

    const photoDetail2 = [[1, 1920, 1282], [2, 1920, 1282], [3, 1920, 1275], [4, 1920, 1275], [5, 1920, 1282], [6, 1920, 1282], [7, 1920, 1282], [8, 1920, 1282]];
    const [photos, setPhotos] = useState([]);
    const [showPhotoModal, setShowPhotoModal] = useState(false);
    const [currentPhoto, setCurrentPhoto] = useState(null);
    const shouldFetch = useRef(true);

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Inicio" });
        if (shouldFetch.current) {
            shouldFetch.current = false;
            const photosTemp = [];
            getMultipleRandom(photoDetail2, 4).forEach((photo) => {
                photosTemp.push({
                    src: process.env.PUBLIC_URL + `/imgs/matrimonio2/${photo[0]}.jpg`,
                    width: photo[1],
                    height: photo[2]
                });
            });
            setPhotos(photosTemp);
        }

    }, []);

    const onPhotoClick = (event, photo, index) => {
        setShowPhotoModal(true);
        setCurrentPhoto(photo);
    }

    const handleCloseModal = () => {
        setShowPhotoModal(false);
        setCurrentPhoto(null);
    }

    return (
        <Fragment>
            <Container fluid={true} className="py-1 mb-5 header-portada">
                <Container className="py-1">
                    <Row className='justify-content-start'>
                        <Col md={5} className=''>
                            <Image src={logo} fluid style={{ maxHeight: '477px' }}></Image>
                        </Col>
                        <Col md={7} className=''>
                            <Carousel controls={false} indicators={false} className='carousel-portada' fade style={{ maxHeight: '477px' }}>

                                {images.map((item, index) => {
                                    return <Carousel.Item key={'carousel_' + index}>
                                        <Image fluid src={process.env.PUBLIC_URL + `/imgs/matrimonio/${item}.jpg`} className="mx-auto d-block" />
                                    </Carousel.Item>
                                })

                                }
                            </Carousel>
                        </Col>
                    </Row>
                </Container>
            </Container>
            {/* <div className="container-fluid bg-primary py-5 mb-5 header-portada">
                <div className="container py-5">
                    <div className="row justify-content-start">
                        <div className="col-lg-8 text-center text-lg-start">
                            <h1 className="font-secondary text-primary mb-4">Rituales de unión</h1>
                            <h1 className="display-1 text-primary mb-4">Elemental</h1>
                            <h1 className="text-white">Tus pensamientos se hacen realidad</h1>
                            <div className="d-flex align-items-center justify-content-center justify-content-lg-start pt-5">
                                <Link to="/" className='btn btn-primary py-3 px-5 me-5'>Leer más</Link>
                                <a href="" className="btn btn-primary border-inner py-3 px-5 me-5">Read More</a>
                                <Button onClick={() => setModalShow(true)} className="btn-play"><span></span></Button>
                                <h5 className="font-weight-normal text-white m-0 ms-4 d-none d-sm-block">Ver Video</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

            <Modal aria-labelledby="contained-modal-title-vcenter" centered show={modalShow} onHide={() => setModalShow(false)} >
                <Modal.Header closeButton>
                    <Modal.Title>Nosotras</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className="ratio ratio-16x9">
                        <iframe className="embed-responsive-item" title='asd' src="https://www.youtube.com/embed/C6OfZLUff1A" id="video" allowfullscreen allowscriptaccess="always"
                            allow="autoplay"></iframe>
                    </div>
                </Modal.Body>
            </Modal>

            <div className="container-fluid pt-5">
                <div className="container">
                    <div className="section-title position-relative text-center mx-auto mb-5 pb-3" style={{ maxWidth: '600px' }}>
                        {/* <h2 className="text-primary-2 font-secondary">Nosotras</h2> */}
                        <h1 className="display-4 text-uppercase font-title">¿Qué Ofrecemos?</h1>
                    </div>
                    <div className="row gx-5">
                        <div className="col-lg-5 mb-5 mb-lg-0" style={{ minHeight: '400px' }}>
                            <div className="position-relative h-100">
                                <img className="position-absolute w-100 h-100" alt='' src={process.env.PUBLIC_URL + '/imgs/matrimonio/4.jpg'} style={{ objectFit: 'cover' }} />
                            </div>
                        </div>
                        <div className="col-lg-6 pt-5">
                            <p className="mb-5 text-primary-2">En “ELEMENTAL” queremos entregar a todas las personas que decidan unirse y emprender un camino juntas, ceremonias rituales de unión profundamente significativas y personalizadas, enfocadas completamente en los valores y creencias de cada pareja, las cuales sean capaces de bendecir el amor, la decisión, el camino y los pasos que se necesiten,  para que la aventura que significa unirse con un otro comience llena de fortuna y abundancia, dentro de un ambiente único, místico y mágico.</p>
                        </div>
                    </div>
                </div>
            </div>


            <div className="container-fluid pt-5">
                <div className="container">
                    <div className="section-title position-relative text-center mx-auto mb-5 pb-3" style={{ maxWidth: '600px' }}>
                        {/* <h2 className="text-primary-2 font-secondary">Nosotras</h2> */}
                        <h1 className="display-4 text-uppercase font-title">Galería</h1>
                    </div>
                    <div className="row gx-5">
                        {photos.length > 0 &&
                            <PhotoAlbum layout="rows" photos={photos} onClick={onPhotoClick} />
                        }

                    </div>
                    <div className="row pt-4">
                        <Col className='text-center'>
                            <Link to={'/gallery'} >
                                <Button variant='primary' size="lg" style={{fontSize:'x-large'}}>
                                    Ir a Galería
                                </Button>
                            </Link>
                        </Col>


                    </div>
                </div>
            </div>


            <Modal size="lg" show={showPhotoModal && currentPhoto} onHide={() => handleCloseModal()} animation={false} aria-labelledby="contained-modal-title-vcenter"
                centered>
                {/* <Modal.Header closeButton>
          <Modal.Title>Matrimonio</Modal.Title>
        </Modal.Header> */}
                <Modal.Body>
                    {photos.length > 0 &&
                        <Image src={currentPhoto?.src || photos[0].src} style={{ maxWidth: '100%', maxHeight: '100%' }} />
                    }

                </Modal.Body>
            </Modal>
        </Fragment>
    );
};

export default DashboardPage;