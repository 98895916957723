import React, { Fragment, useEffect } from 'react';
import { Button, Col, Container, Form, Image, Row } from 'react-bootstrap';
import ReCAPTCHA from "react-google-recaptcha";
import { useForm } from "react-hook-form";
import axios from 'axios';
import { toast } from 'react-toastify';
import ReactGA from 'react-ga4' ;

const ContactPage = () => {
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Contacto" });
      }, []);
    const recaptchaRef = React.createRef({});
    const { register, handleSubmit, formState: { errors } } = useForm();
    const opcionesToast = {
        position: toast.POSITION.TOP_CENTER, theme: "colored"
      };
    const continueOnSubmit = (formData, tokenCaptcha, recaptchaRef) => {
        let data = formData;
        data.token = tokenCaptcha;
        axios({
            url: "https://www.elementalceremonias.cl/api/contact",
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            headers: {
                'Content-Type': 'application/json'
            },
            //referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            data: data//body: JSON.stringify(data) // body data type must match "Content-Type" header
        })
            .then((resp) => 
            {
                toast.success(resp.data.message, opcionesToast);
            })
            .catch((error) => {
                toast.error(error.message ?? 'Ocurrió un error. Intente nuevamente.', opcionesToast);
            }).finally(() => {}); //() => recaptchaRef.current.reset()
    }

    const onSubmit = (data) => {
        recaptchaRef.current?.reset();
        recaptchaRef.current.executeAsync()
            .then(function(token) { continueOnSubmit(data, token);})
            .catch(e => toast.error(e ?? 'Ocurrió un error. Intente nuevamente.', opcionesToast));
    }

    // onChange={(value) => {
    //     recaptchaVal.current = value;
    //     console.log(`There you go ${value}`);
    //   }}

    return (
        <Fragment>
            <div className="section-title position-relative text-center mx-auto mb-5 mt-5 pb-3" style={{ maxWidth: '600px' }}>
                <h1 className="display-4 text-uppercase font-title">Contacto</h1>
            </div>
            <Container fluid={true} className="py-1 mb-5">
                <Container className="py-1">
                    <Row className='justify-content-start'>
                        <Col md={5} className=''>
                            <Image src={process.env.PUBLIC_URL + '/imgs/equipo/contacto_elemental_ancho.jpg'} style={{ objectFit: 'cover' }} fluid></Image>
                        </Col>
                        <Col md={7} className='text-primary-2 align-items-center'>
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Nombre</Form.Label>
                                    <Form.Control className='text-primary-2' type="text"  {...register("nombre", { required: true, minLength: 3, maxLength: 50 })} placeholder="Ingrese su nombre y apellido" />
                                    {errors.nombre && <span className='text-error'>(*) Campo requerido (mínimo 3 caracteres y máximo 50)</span>}
                                </Form.Group>
                                <Form.Group className="mb-3" >
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control className='text-primary-2' type="email" placeholder="Ingrese el email" {...register("email", { required: true, minLength: 3, maxLength: 100 })} />
                                    {errors.email && <span className='text-error'>(*) Campo requerido (mínimo 3 caracteres y máximo 100)</span>}
                                </Form.Group>

                                <Form.Group className="mb-3" >
                                    <Form.Label>Mensaje</Form.Label>
                                    <Form.Control className='text-primary-2' as="textarea" placeholder="Mensaje" {...register("mensaje", { required: true, minLength: 20, maxLength: 1000 })} />
                                    {errors.mensaje && <span className='text-error'>(*) Campo requerido (mínimo 20 caracteres y máximo 1000)</span>}
                                </Form.Group>
                                <Button variant="primary" type="submit">
                                    <i className="bi bi-send" ></i> Enviar
                                </Button>
                                <ReCAPTCHA
                                    ref={recaptchaRef}
                                    size="invisible"
                                    sitekey="6LciT7IiAAAAAOfzolXIj8Hmg2CrMs7OA6scB99U"
                                    
                                />
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </Fragment>
    );
};

export default ContactPage;