import HeaderComponent from './components/HeaderComponent';
import FooterComponent from './components/FooterComponent';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import DashboardPage from './pages/public/DashboardPage';
import ServicesPage from './pages/public/ServicesPage';
import TeamPage from './pages/public/TeamPage';
import RitualsPage from './pages/public/RitualsPage';
import GalleryPage from './pages/public/GalleryPage';
import TermsPage from './pages/public/TermsPage';
import ContactPage from './pages/public/ContactPage';
import ServiceDetailsPage from './pages/public/ServiceDetailsPage';
import ReactGA from 'react-ga4' ;

import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import ScrollToTopComponent from './components/ScrollToTopComponent';
import { useEffect } from 'react';

const GOOGLE_APP_ID = 'G-KWMZ6XXETQ';
ReactGA.initialize(GOOGLE_APP_ID);

function App() {
  
  return (
    <Router >
      <ScrollToTopComponent />
      <ToastContainer />
      <HeaderComponent />
      {/* <div style={{height: '60px'}}></div> */}
        <Routes>
            
            <Route element={<DashboardPage />} path="/" />
            <Route element={<ServicesPage />} path="/services" />
            <Route element={<TeamPage />} path="/team" />
            <Route element={<RitualsPage />} path="/rituals" />
            <Route element={<GalleryPage />} path="/gallery" />
            <Route element={<TermsPage />} path="/terms" />
            <Route element={<ContactPage />} path="/contact" />
            <Route element={<ServiceDetailsPage />} path="/details" />
        </Routes>
        <FooterComponent />
    </Router>
  );
}

export default App;
