import React, { Fragment, useEffect } from 'react';
import ReactGA from 'react-ga4' ;

const ServicesPage = () => {
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "¿Qué Ofrecemos?" });
      }, []);
    return (
        <Fragment>
            <div className="section-title position-relative text-center mx-auto mb-5 mt-5 pb-3" style={{ maxWidth: '600px' }}>
                <h1 className="display-4 text-uppercase font-title">¿Qué Ofrecemos?</h1>
            </div>
            <div className="container-fluid">
                <div className="container">
                    {/* <div className="section-title position-relative text-center mx-auto mb-5 pb-3" style={{ maxWidth: '600px' }}>
                        <h1 className="display-4 text-uppercase">¿Qué Ofrecemos?</h1>
                    </div> */}
                    <div className="row gx-5  text-justify">
                        <div className="col-lg-5 mb-5 mb-lg-0" style={{ minHeight: '400px' }}>
                            <div className="position-relative h-100">
                                <img className="position-absolute w-100 h-100" alt='' src={process.env.PUBLIC_URL + '/imgs/matrimonio/4.jpg'} style={{ objectFit: 'cover' }} />
                            </div>
                        </div>
                        <div className="col-lg-6 pb-5">
                            <h4 className="mb-4">Rituales de Unión</h4>
                            <p className="mb-5 text-primary-2 fst-italic">"Un rito es un conjunto de acciones ceremoniales que poseen un alto valor simbólico para quienes las crean y realizan. Estas acciones pueden estar inscritas en un credo, una ideología o una tradición cultural. La necesidad que nosotros como humanos hemos tenido del rito es ancestral, y ha estado vinculada a distintas temáticas de interés propias de nuestra especie humana,  puesto que se relacionan con la manifestación integral, es decir,  corpóreo-espiritual de la dimensión sagrada de la existencia personal.”</p>
                            <p className="mb-5 text-primary-2">En “ELEMENTAL” queremos entregar a todas las personas que decidan unirse y emprender un camino juntas, ceremonias rituales de unión profundamente significativas y personalizadas, enfocadas completamente en los valores y creencias de cada pareja, las cuales sean capaces de bendecir el amor, la decisión, el camino y los pasos que se necesiten,  para que la aventura que significa unirse con un otro comience llena de fortuna y abundancia, dentro de un ambiente único, místico y mágico.</p>
                        </div>
                        
                    </div>
                    <div className="row g-5 mt-4 text-primary-2 text-justify">
                        <div className="col-sm-6">
                            <div className="d-flex align-items-center justify-content-center bg-primary border-inner mb-4 " style={{width: '90px', height: '90px'}}>
                                <i className="bi bi-fire text-white" style={{fontSize:'xxx-large'}}></i>
                            </div>
                            <h4 className="text-uppercase"> Fuego</h4>
                            <p className="mb-0">Elemento que potencia la energía de la acción y el impulso, llevándonos a tomar aventuras y riesgos. Nos conecta con los deseos y necesidades del corazón. También se le asocia a la pasión e intensidad en las relaciones.</p>
                        </div>
                        <div className="col-sm-6">
                            <div className="d-flex align-items-center justify-content-center bg-primary border-inner mb-4" style={{width: '90px', height: '90px'}}>
                                <i className="bi bi-flower3 text-white" style={{fontSize:'xxx-large'}}></i>
                            </div>
                            <h4 className="text-uppercase">Tierra</h4>
                            <p className="mb-0">Elemento que potencia la energía orgánica que se tiene con nuestros cuerpos y la naturaleza. Conecta con lo concreto y material del mundo físico que se refleja en nuestra realidad. Energía muy asociada a la seguridad material (dinero), estabilidad y perseverancia</p>
                        </div>
                        <div className="col-sm-6">
                            <div className="d-flex align-items-center justify-content-center bg-primary border-inner mb-4" style={{width: '90px', height: '90px'}}>
                                <i className="bi bi-wind text-white" style={{fontSize:'xxx-large'}}></i>
                            </div>
                            <h4 className="text-uppercase">Aire</h4>
                            <p className="mb-0">Elemento que potencia la energía de la comunicación y  creatividad. Muy conectada con el pensamiento y la palabra. Se asocia al aprendizaje de nuevas cosas, a la sociabilización y a la originalidad.</p>
                        </div>
                        <div className="col-sm-6">
                            <div className="d-flex align-items-center justify-content-center bg-primary border-inner  mb-4" style={{width: '90px', height: '90px'}}>
                                <i className="bi bi-droplet-fill text-white" style={{fontSize:'xxx-large'}}></i>
                            </div>
                            <h4 className="text-uppercase">Agua</h4>
                            <p className="mb-0">Elemento que potencia la energía de conexión con el mundo de las emociones. Se le asocia con la intuición, la sensibilidad y la empatía.</p>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default ServicesPage;