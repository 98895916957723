import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTopComponent() {
    const {pathname} = useLocation();
    useEffect(() => {
        const isMobile = window.matchMedia && window.matchMedia("(max-width: 992px)").matches;
        const top = isMobile ? 0 : 105;
        window.scrollTo(0,top);
    }, [pathname]);
    return null;
}