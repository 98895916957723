import React, { Fragment, useEffect, useState  } from "react";
import { NavLink } from "react-router-dom";
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';

const HeaderComponent = () => {
    const [navbarClass, setNavbarClass] = useState('navbar-rose');
    const stickyClass = 'navbar-rose sticky-top';
    const [expanded, setExpanded] = useState(false);
    
    useEffect(() => {
        const handleScroll = () => {
            const position = window.pageYOffset;
            //console.log(position);
            if (position > 40) {
                if(navbarClass.localeCompare(stickyClass) !== 1)
                    setNavbarClass(stickyClass);
            } else {
                if(navbarClass.localeCompare('navbar-rose')  !== 1)
                    setNavbarClass('navbar-rose');
            }
          };
        window.addEventListener("scroll", handleScroll);
    
        return () => {
          window.removeEventListener("scroll", handleScroll);
        };
      }, []);

    return (
        <Fragment>

            <Container fluid className="px-0 d-none d-lg-block">
                <div className="row gx-0">
                    <div className="col-lg-4 text-center py-3">
                        <div className="d-inline-flex align-items-center justify-content-center">
                            <i className="bi bi-envelope fs-1 text-primary me-3"></i>
                            <div className="text-start text-uppercase text-dark">
                                <h5 className="mb-1">Email</h5>
                                <span><a href="mailto:contacto@elementalceremonias.cl?Subject=Consulta%20Elemental">contacto@elementalceremonias.cl</a></span>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 text-center border-inner py-3">
                        <div className="d-inline-flex align-items-center justify-content-center">
                            <a href="/" className="navbar-brand">
                                <h1 className="m-0 text-primary text-uppercase font-title title title-rose" >Elemental</h1>
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-4 text-center py-3">
                        <div className="d-inline-flex align-items-center justify-content-center">
                            <i className="bi bi-phone-vibrate fs-1 text-primary me-3"></i>
                            <div className="text-start text-uppercase text-dark">
                                <h5 className=" mb-1">Whatsapp</h5>
                                <span><a target={'_blank'} rel='noreferrer' href="https://api.whatsapp.com/send?phone=56982986675">+56 9 8298 6675</a></span>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
            <Navbar expanded={expanded} expand="lg" className={navbarClass} >
                <Container>
                    <Navbar.Brand href="/" className="navbar-brand text-white  d-block d-lg-none ">ELEMENTAL CEREMONIAS</Navbar.Brand> {/* d-block d-lg-none */}
                    <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(expanded ? false : "expanded")} />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className=" ms-auto mx-lg-auto py-0" > {/* ms-auto mx-lg-auto py-0 */}
                            <NavLink to="/" className="nav-item nav-link" onClick={() => setExpanded(false)}>HOME</NavLink>
                            <NavLink to="/team" className="nav-item nav-link" onClick={() => setExpanded(false)}>¿Quiénes Somos?</NavLink>
                            <NavLink to="/services" className="nav-item nav-link" onClick={() => setExpanded(false)}>¿Qué Ofrecemos?</NavLink>
                            <NavLink to="/rituals" className="nav-item nav-link" onClick={() => setExpanded(false)}>Rituales</NavLink>
                            <NavLink to="/gallery" className="nav-item nav-link" onClick={() => setExpanded(false)}>Galeria</NavLink>
                            <NavLink to="/details" className="nav-item nav-link" onClick={() => setExpanded(false)}>Detalles</NavLink>
                            <NavLink to="/contact" className="nav-item nav-link" onClick={() => setExpanded(false)}>Contacto</NavLink>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            {/* <nav className="navbar navbar-expand-lg bg-dark navbar-dark shadow-sm py-3 py-lg-0 px-3 px-lg-0">
                <a href="index.html" className="navbar-brand d-block d-lg-none">
                    <h1 className="m-0 text-uppercase text-white"><i className="fa fa-birthday-cake fs-1 text-primary me-3"></i>CakeZone</h1>
                </a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarCollapse">
                    <div className="navbar-nav ms-auto mx-lg-auto py-0">
                        <Link to="/" className="nav-item nav-link">Inicio</Link>
                        <Link to="/about-us" className="nav-item nav-link">Nosotras</Link>
                        <Link to="/services" className="nav-item nav-link">Servicios</Link>
                        <Link to="/contact" className="nav-item nav-link">Contacto</Link>
                    </div>
                </div>
            </nav> */}
        </Fragment>
    )
}


export default HeaderComponent;