import React, { Fragment, useEffect } from 'react';
import ReactGA from 'react-ga4' ;

const RitualsPage = () => {
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Rituales" });
      }, []);
    return (
        <Fragment>
            <div className="section-title position-relative text-center mx-auto mb-3 mt-5 pb-3" style={{ maxWidth: '600px' }}>
                <h1 className="display-4 text-uppercase font-title">Rituales</h1>
            </div>
            <div className="container-fluid mt-5">
                <div className="container text-justify">
                    {/* <div className="section-title position-relative text-center mx-auto mb-5 pb-3" style={{ maxWidth: '600px' }}>
                        <h1 className="display-4 text-uppercase">¿Qué Ofrecemos?</h1>
                    </div> */}
                    <div className="row gx-5 mb-5">
                        <div className="col-lg-5 mb-5 mb-lg-0" style={{ minHeight: '400px' }}>
                            <div className="position-relative h-100">
                                <img className="position-absolute w-100 h-100" alt='' src={process.env.PUBLIC_URL + '/imgs/matrimonio/27.jpg'} style={{ objectFit: 'cover' }} />
                            </div>
                        </div>
                        <div className="col-lg-6 ">
                            <h2 className="mb-1 font-title">OPCIÓN ELEMENTAL</h2>
                            <h4 className="mb-4 fst-italic font-title">Ceremonia de los cuatro elementos.</h4>
                            <p className="text-primary-2">La pareja tendrá la posibilidad de bendecir su unión por medio de una opción estándar de ceremonia, la cual tiene como sello especial la presencia de los cuatro elementos (fuego, tierra, aire, agua) como fuerzas y energías protagónicas del viaje estructural estándar que se propone.</p>
                            <p className="text-primary-2">Algunas de las etapas de la ceremonia podrán ser personalizadas, pudiendo escoger cuál de los cuatro elementos (con su respectivo ritual) estará presente con más fuerza en cada momento. Esta selección será realizada  en la primera entrevista con la pareja. Solicita aquí tu entrevista.</p>
                            <p className="text-primary-2">La opción Elemental contará con la presencia de un altar decorado con objetos que estarán en diálogo con las opciones seleccionadas.</p>
                            <p className="text-primary-2 fst-italic">(*) Esta opción cuenta con la posibilidad de realizar cambios y ajustes personalizados, con variaciones o agregados según se solicite (sumando un costo extra al precio total).</p>

                        </div>
                    </div>

                    <hr style={{ color: 'darkmagenta' }} />

                    <div className="row gx-5 mt-5">

                        <div className="col-lg-6">
                            <h2 className="mb-1 font-title">OPCIÓN ÉTER</h2>
                            <h4 className="mb-4 fst-italic font-title">Ceremonia a tu medida.</h4>
                            <p className="text-primary-2">La pareja tendrá la posibilidad de bendecir su unión por medio de la realización de una ceremonia hecha íntegramente a la medida de la pareja.</p>
                            <p className="text-primary-2">En la opción Éter se plasmará las necesidades, deseos y creencias espirituales de la pareja, entregándoles así la posibilidad de convertirse en co-creadores activos de su unión.</p>
                            <p className="text-primary-2">Por medio de una entrevista se acordará que es lo sagrado para la pareja, y se creará en conjunto un viaje ceremonial que dará cuenta de la unión mística deseada. Solicita aquí tu entrevista.</p>
                            <p className="text-primary-2">Así mismo, esta ceremonia contará con la presencia de un altar especialmente decorado con objetos que estarán en diálogo con el viaje ritual creado.</p>

                        </div>
                        <div className="col-lg-5 mb-5 mb-lg-0" style={{ minHeight: '400px' }}>
                            <div className="position-relative h-100">
                                <img className="position-absolute w-100 h-100" alt='' src={process.env.PUBLIC_URL + '/imgs/matrimonio2/2.jpg'} style={{ objectFit: 'cover' }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default RitualsPage;