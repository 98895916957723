import React, { Fragment, useEffect } from 'react';
import ReactGA from 'react-ga4' ;

const TermsPage = () => {
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Condiciones del Servicio" });
      }, []);
    return (
        <Fragment>
            <div className="container-fluid bg-dark bg-img p-5 mb-5">
                <div className="row">
                    <div className="col-12 text-center">
                        <h1 className="display-4 text-uppercase text-white font-title">CONDICIONES DE SERVICIO</h1>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="container">
                    {/* <div className="section-title position-relative text-center mx-auto mb-5 pb-3" style={{ maxWidth: '600px' }}>
                        <h1 className="display-4 text-uppercase">¿Qué Ofrecemos?</h1>
                    </div> */}
                    <div className="row gx-5">
                        <div className="col-lg-12 text-primary-2 text-justify">

                            <ol >
                                <h2 dir="ltr" role="presentation"><li>CONDICIONES GENERALES DE LA PÁGINA WEB</li></h2>

                                <p>Este sitio web es operado por Elemental. En todo el sitio, los términos "nosotras", "nos" y "nuestro" se refieren a Elemental. Elemental ofrece este sitio web, que incluye toda la información de los servicios disponibles para ti, el usuario, en este sitio, condicionado a la aceptación de todos los términos aquí establecidos.</p>

                                <p>Lea estos Términos de servicio detenidamente antes de acceder o utilizar nuestro servicio. Al acceder o usar cualquier parte del sitio, acepta estar sujeto a estos Términos de servicio. Si no está de acuerdo con todos los términos y condiciones de este acuerdo, no podrá acceder al servicio que ofrece el sitio web.</p>

                                <p>Elemental se reserva la posibilidad de modificar sin previo aviso, el diseño, presentación y/o configuración de este Sitio Web, así como algunos o todos los servicios o funcionalidades del mismo, pudiendo suprimir y añadir unas u otros.</p>


                                <h4>OBJETO</h4>

                                <p>Las presentes Condiciones del Servicio buscan establecer las responsabilidades y obligaciones para el entendimiento y buen uso del servicio que entrega Elemental. </p>

                                <p className='fst-italic'>Realizar ceremonias y celebraciones de unión espirituales, simbólicas, con un ritual íntimo, emocionante e inolvidable para cada pareja.</p>


                                <h4>OBLIGACIONES DE LOS USUARIOS EN EL SITIO WEB</h4>

                                <p>El Usuario se compromete a hacer un uso diligente del sitio web y de los servicios accesibles desde éste, con total sujeción a la Ley, a las buenas costumbres y a las presentes Condiciones del Servicio. </p>

                                <p>Toda la información que facilite el usuario a través de cualquiera de los mecanismos habilitados para obtener la prestación de los servicios ofrecidos por Elemental deberá ser veraz y exacta. El usuario garantiza la autenticidad de todos aquellos datos que comunique como consecuencia del cumplimiento de los formularios o respuestas solicitadas por Elemental y que sean necesarios para la suscripción de los Servicios.</p>

                                <p>El Usuario será el único responsable de las manifestaciones falsas o inexactas que realice, de la información falsa o inexacta que facilite y de los perjuicios que esto pueda provocar. </p>

                                <p>El Usuario se obliga a respetar las leyes aplicables y los derechos de propiedad al utilizar los contenidos y servicios del Sitio Web. Asimismo, queda prohibida la reproducción, distribución, transmisión, adaptación o modificación, por cualquier medio y en cualquier forma, de los contenidos del Sitio Web (textos, diseños, gráficos, informaciones, bases de datos, archivos de sonido y/o imagen, logos, etc.) y demás elementos obtenidos del servicio, salvo autorización previa de sus legítimos titulares o cuando así resulte permitido por la ley.</p>

                                <p>Se prohíbe al usuario llevar a cabo, entre otras acciones, y de modo enunciativo, pero no limitativo, las siguientes:</p>

                                <ul>
                                    <li><p>Copiar, difundir y reproducir pública y lucrativamente contenidos del servicio.</p>

                                    </li>
                                    <li><p>Utilizar cualquiera de los materiales e informaciones contenidos en este Sitio Web con fines ilícitos y/o expresamente prohibidos en las presentes Condiciones del servicio, así como en las condiciones particulares que, en su caso, se habiliten y/o que resulten contrarios a los derechos e intereses de Elemental, sus miembros y/o terceros. </p>

                                    </li>
                                </ul>


                                <h4>RESPONSABILIDAD Y DIFUSIÓN DE CONTENIDOS </h4>

                                <p>Elemental con el consentimiento de la pareja podrá utilizar y difundir los contenidos generados en la realización de los servicios o que hubieran sido aportados al sitio web, en particular, podrá realizar difusión de los mismos en sitios web o redes sociales en los que Elemental tenga presencia o gestione. </p>

                                <h2 className='mt-4' dir="ltr" role="presentation"><li>CONDICIONES GENERALES DEL SERVICIO</li></h2>

                                <h4>OBLIGACIONES DE LA PAREJA AL OPTAR POR UN SERVICIO</h4>

                                <p>El servicio de rituales de unión sólo será prestado bajo la condición principal de que las partes decidan hacerlo de forma libre, voluntaria y con total consentimiento.</p>

                                <p>La pareja, o titulares del servicio, se comprometen a que toda información que entregue sea verídica, dicha información será usada por nosotras a posteriori para crear la ceremonia. Como también se comprometen a realizar tareas o cumplimiento de fechas que se ameriten para realización de la ceremonia. </p>

                                <p>Por lo demás, se requerirá de su opinión en tareas como la elección de los elementos representativos de los cuatro elementos que serán utilizados en el altar. Así como todos los detalles para la opción ÉTER ritual a medida. </p>

                                <p>La pareja tendrá la opción de realizar un Ensayo de Ceremonia, por un cargo extra del 10% del valor del servicio. En el ensayo acuden todos los participantes y músicos que requiera el ritual, con el fin de ordenar y dar los tiempos necesarios para cada acción en la ceremonia. La coordinación de los participantes y músicos corre por parte de la pareja. </p>

                                <p>La presencia y puntualidad de la pareja en las reuniones necesarias es de su exclusiva responsabilidad. En caso de requerir cambio de fechas u horarios de las reuniones, se debe dar aviso con 24 hrs de anticipación.</p>

                                <p>La pareja deberá estipular la fecha de la ceremonia antes de la firma de contrato. El cambio posterior de esta fecha queda sujeto a nuestra disponibilidad y a un cargo de 15% del valor de ritual. </p>


                                <h4>MODIFICACIONES AL SERVICIO Y PRECIOS</h4>

                                <p>Los precios de nuestros servicios están sujetos a cambios sin previo aviso. Asimismo, respetaremos todo contrato gestionado con anterioridad a la fecha de cambios.

                                    Nos reservamos el derecho en cualquier momento de modificar o interrumpir el Servicio (o cualquier parte o contenido del mismo) sin previo aviso, a excepción de contratos ya firmados. </p>


                                <h4>PRECIOS, POLÍTICAS DE PAGO Y CARGOS EXTRAS</h4>

                                <p>El precio del servicio de ceremonias de unión dependerá del tipo de ceremonia a realizar, habiendo dos opciones; ritual Elemental y Éter. El ritual Elemental, de los cuatro elementos, tiene un valor de $ 350.000.-. El ritual Éter no tiene un precio establecido ya que depende de la personalización que decide la pareja, pudiendo variar desde $ 480.000.-.</p>

                                <p>Para concretar la reserva del servicio y la fecha de celebración del evento, el cliente debe realizar un abono del 50% del total del presupuesto. </p>

                                <p>El pago total de servicio se debe realizar hasta 48 horas antes de la celebración de la ceremonia. </p>

                                <p>El traslado de la sacerdotisa y organizadora se incluye en presupuesto exceptuando fuera de la zona urbana de la Región Metropolitana, en caso de solicitar el servicio en regiones se cobrarán viáticos extras según distancia.</p>

                                <p>La llegada de la sacerdotisa y organizadoras el día de la ceremonia es de 30 a 60 minutos antes.</p>

                                <p>La amplificación del sonido, micrófonos y cualquier artefacto de sonido para la ceremonia, será proporcionada por el cliente, en caso contrario se dispondrá un costo extra de $50.000</p>


                                <h4>POLÍTICAS DE CANCELACIÓN Y RESERVAS</h4>

                                <p>Una vez aprobado el presupuesto, la pareja reserva la fecha pagando el 40% del mismo. Se firma el contrato de forma online. Después de realizadas las firma se agenda reuniones para dar inicio al proceso de entrevistas.</p>

                                <p>La pareja tiene hasta 5 días hábiles post pago de reserva para realizar cancelación del servicio, en donde se devolverá el 40% del total del pago de la reserva. </p>

                                <p>Posterior a 5 días hábiles, si la cancelación de la ceremonia corre por parte de la pareja no se realizan devoluciones del servicio. </p>

                                <p>Se pueden hacer reprogramaciones de fechas dependiendo de nuestra disponibilidad de agenda.</p>



                            </ol>
                        </div>


                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default TermsPage;