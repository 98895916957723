import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Modal, Image } from 'react-bootstrap';
import PhotoAlbum from "react-photo-album";
import ReactGA from 'react-ga4' ;


const GalleryPage = () => {
    const photoDetail= [[5, 1920, 1541], [7, 1920, 1541], [10, 1280, 1920],[17,1920,1280], [20,1920,1236], [22,1920,1280], [28,1920,1364], [29,1920,1280]];
    const photoDetail2 = [[1, 1920, 1282], [2, 1920, 1282], [3, 1920, 1275],[4, 1920, 1275], [5, 1920, 1282], [6, 1920, 1282], [7, 1920, 1282], [8, 1920, 1282]];
    const photoDetail3 = [[1, 350, 525], [2, 350, 233], [3, 350, 233],[4, 350, 233], [5, 350, 233], [6, 350, 233], [7, 350, 233], [8, 350, 233]];
    const photoDetail4 = [[1, 350, 481], [2, 350, 467], [3, 350, 467],[4, 350, 467], [5, 350, 263], [6, 350, 263], [7, 350, 467], [8, 350, 467]];
    const [galleries, setGalleries] = useState(null);
    const shouldFetch = useRef(true);
    const [showModal, setShowModal] = useState(false);
    const [currentPhoto, setCurrentPhoto] = useState(null);
    
    const getPhoto = (photo, folder) => {
        return {
            src: process.env.PUBLIC_URL + `/imgs/${folder}/thumbs/${photo[0]}.jpg`,
            width: photo[1],
            height: photo[2],
            full: process.env.PUBLIC_URL + `/imgs/${folder}/${photo[0]}.jpg`
        };
    }

    const addPhotosToGallery = (photoDetail, gallery, folder) => {
        photoDetail.forEach((photo) => {
            gallery.push(getPhoto(photo, folder));
        });
    }
    
    useEffect( () => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Galería de Imágenes" });
        if(shouldFetch.current && !galleries){
            shouldFetch.current = false;
            let newGalleries = {gallery1:[], gallery2: [], gallery3: [], gallery4:[]};
            addPhotosToGallery(photoDetail, newGalleries.gallery1, 'matrimonio');
            addPhotosToGallery(photoDetail2, newGalleries.gallery2, 'matrimonio2');
            addPhotosToGallery(photoDetail3, newGalleries.gallery3, 'matrimonio3');
            addPhotosToGallery(photoDetail4, newGalleries.gallery4, 'matrimonio4');
           setGalleries(newGalleries);
        }
        
    }, []);

    const onPhotoClick = (event, photo, index) => {
        setShowModal(true);
        setCurrentPhoto(photo);
    }

    const handleCloseModal = () => {
        setShowModal(false);
        setCurrentPhoto(null);
    }

    return (
        <Fragment>
            <div className="section-title position-relative text-center mx-auto mb-5 mt-5 pb-3" style={{ maxWidth: '600px' }}>
                <h1 className="display-4 text-uppercase font-title">Galería</h1>
            </div>
            <div className="container-fluid">
                
                <div className="container">
                    <h4>Marisol y Moisés: Ceremonia Elemental (7 de octubre 2023)</h4>
                    <PhotoAlbum layout="columns" photos={galleries?.gallery4} onClick={ onPhotoClick } />
                    <hr />
                    <h4>Ceremonia Éter: Sesión de fotos (15 de Marzo 2023)</h4>
                    <PhotoAlbum layout="columns" photos={galleries?.gallery3} onClick={ onPhotoClick } />
                    <hr />
                    <h4>Ceremonia Elemental: Sesión de fotos (19 de marzo del 2022)</h4>
                    <PhotoAlbum layout="columns" photos={galleries?.gallery1} onClick={ onPhotoClick } />
                    {/* <div className="section-title position-relative text-center mx-auto mb-5 pb-3" style={{ maxWidth: '600px' }}>
                        <h1 className="display-4 text-uppercase">¿Qué Ofrecemos?</h1>
                    </div> */}
                    <hr />
                    <h4>Constanza y Sebastián: Ceremonia Elemental (23 de octubre del 2021)</h4>
                    <PhotoAlbum layout="columns" photos={galleries?.gallery2} onClick={ onPhotoClick } />
                    
                    <div className="row gx-5">
                        
                    </div>
                </div>
            </div>

            <Modal size="lg" show={showModal && currentPhoto} onHide={() => handleCloseModal()} animation={false} aria-labelledby="contained-modal-title-vcenter"
      centered>
        {/* <Modal.Header closeButton>
          <Modal.Title>Matrimonio</Modal.Title>
        </Modal.Header> */}
        <Modal.Body><Image src={currentPhoto?.full} style={{maxWidth: '100%', maxHeight: '100%'}} /></Modal.Body>
      </Modal>
        </Fragment>
    );
};

export default GalleryPage;