import React, { Fragment } from "react";
import { Link } from "react-router-dom";

const FooterComponent = () => {
    //const [count, setCount] = useState(0);

    // const increase = () => {
    //     setCount(count+1);
    // }

    return (
        <Fragment>
            <div className="container-fluid bg-img " style={{ marginTop: '90px' }}>
                <div className="container">
                    <div className="row gx-5">
                        {/* <div className="col-lg-4 col-md-6 d-none d-lg-block">
                            <div className="d-flex flex-column align-items-center justify-content-center text-center h-100 bg-primary-2 border-inner p-4">
                            
                                <Link to="/" className="navbar-brand">
                                    <Image src={logo} fluid></Image>
                                    <h1 className="m-0 text-white"><i className="fa fa-birthday-cake fs-1 text-dark me-3"></i>Elemental</h1>
                                </Link>
                                 <p className="mt-3">Descripción</p>
                            </div>
                        </div> */}
                        <div className="col-lg-12 col-md-6">
                            <div className="row gx-5">
                                <div className="col-lg-6 col-md-12 pt-5 mb-5">
                                    <h4 className="text-primary text-uppercase mb-4">Contacto</h4>
                                    <div className="d-flex mb-2">
                                        <i className="bi bi-geo-alt text-primary me-2"></i>
                                        <p className="mb-0">Santiago, Chile</p>
                                    </div>
                                    <div className="d-flex mb-2">
                                        <i className="bi bi-envelope-open text-primary me-2"></i>
                                        <p className="mb-0"><a href="mailto:contacto@elementalceremonias.cl?Subject=Consulta%20Elemental">contacto@elementalceremonias.cl</a></p>
                                    </div>
                                    <div className="d-flex mb-2">
                                        <i className="bi bi-telephone text-primary me-2"></i>
                                        <p className="mb-0"><a target={'_blank'} rel='noreferrer' href="https://api.whatsapp.com/send?phone=56982986675">+56 9 8298 6675</a></p>
                                    </div>
                                    {/* <div className="d-flex mt-4">
                                        <a className="btn btn-lg btn-primary btn-lg-square rounded-0 me-2" href="https://www.twitter.com"><i className="bi bi-twitter"></i></a>
                                        <a className="btn btn-lg btn-primary btn-lg-square rounded-0 me-2" href="https://www.instagram.com"><i className="bi bi-instagram"></i></a>
                                        <a className="btn btn-lg btn-primary btn-lg-square  rounded-0 me-2" href="https://www.facebook.com"><i className="bi bi-facebook"></i></a>
                                    </div> */}
                                </div>
                                <div className="col-lg-6 col-md-12 pt-0 pt-lg-5 mb-5">
                                    <h4 className="text-primary text-uppercase mb-4">Menu</h4>
                                    <div className="d-flex flex-column justify-content-start">
                                        <Link className="" to="/">Inicio</Link>
                                        {/* <Link to="/team" className="nav-item nav-link">¿Quiénes Somos?</Link>
                                        <Link to="/services" className="nav-item nav-link">¿Qué Ofrecemos?</Link>
                                        <Link to="/rituals" className="nav-item nav-link">Rituales</Link>
                                        <Link to="/gallery" className="nav-item nav-link">Galeria</Link>
                                        <Link to="/details" className="nav-item nav-link">Detalles</Link>
                                        <Link to="/contact" className="nav-item nav-link">Contacto</Link> */}
                                        <Link to="/terms" className="nav-item nav-link">Condiciones</Link>
                                    </div>
                                </div>
                                {/* <div className="col-lg-4 col-md-12 pt-0 pt-lg-5 mb-5">
                             <h4 className="text-primary text-uppercase mb-4">Newsletter</h4>
                            <p>Subscribirse a Noticias</p>
                            <form action="">
                                <div className="input-group">
                                    <input type="text" className="form-control border-white p-3" placeholder="Your Email" />
                                    <button className="btn btn-primary">Sign Up</button>
                                </div>
                            </form> 
                        </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid  py-4" style={{ background: "#111111" }}>
                <div className="container text-center">
                    <p className="mb-0">&copy; <a className="text-white border-bottom" href="https://www.elementalceremonias.cl">Ceremonias Elemental</a>, Todos los derechos reservados.</p>

                    <p className="d-none d-print-block">Diseñado por <a className="text-white border-bottom" href="https://htmlcodex.com">HTML Codex</a></p>
                </div>
            </div>
        </Fragment>
    )
}


export default FooterComponent;