import React, { Fragment, useEffect } from 'react';
import ReactGA from 'react-ga4' ;

const ServiceDetailsPage = () => {
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Detalles del Servicio" });
      }, []);
    return (
        <Fragment>
            <div className="section-title position-relative text-center mx-auto mb-5 mt-5 pb-3" style={{ maxWidth: '600px' }}>
                <h1 className="display-4 text-uppercase font-title">Detalles del Servicio</h1>
            </div>
            <div className="container-fluid">
                <div className="container">
                    {/* <div className="section-title position-relative text-center mx-auto mb-5 pb-3" style={{ maxWidth: '600px' }}>
                        <h1 className="display-4 text-uppercase">¿Qué Ofrecemos?</h1>
                    </div> */}
                    <div className="row gx-5 mb-5">
                        <div className="col-lg-5 mb-5 mb-lg-0" style={{ minHeight: '400px' }}>
                            <div className="position-relative h-100">
                                <img className="position-absolute w-100 h-100" alt='' src={process.env.PUBLIC_URL + '/imgs/matrimonio/29.jpg'} style={{ objectFit: 'cover' }} />
                            </div>
                        </div>
                        <div className="col-lg-6  text-primary-2 text-justify">

                            <ul >
                                <li><p><b>Trabajo en conjunto con la pareja en la creación del ritual:</b> Incluye primera entrevista y 2 reuniones de preparación y coordinación.</p>
                                    <p>Modo opcional con costo extra, ensayo de ceremonia.</p></li>
                                <li><p><b>Acompañamiento en el proceso de tareas para la pareja en caso de que amerite:</b> Se establecen plazos de entrega y se explica a cabalidad el objetivo de cada tarea asignada, además de realizar seguimiento del proceso con cada pareja.</p></li>
                                <li><p><b>Producción y coordinación de la ceremonia previo al día del evento:</b> Luego de planificar los detalles de la ceremonia ritual con la pareja, se procede a coordinar por vía Whatsapp o telefónica a los participantes extras del ritual, además de otros detalles a considerar.</p></li>
                                <li><p><b>Producción y coordinación de la ceremonia el día del evento: </b></p>
                                    <ul>
                                        <li>
                                            <p>Guía y asistencia a los invitados.</p>
                                        </li>
                                        <li>
                                            <p>Entrega de pauta de guión y coordinación de los participantes del Ritual.</p>
                                        </li>
                                        <li>
                                            <p>Asistencia continua a la pareja (Arreglo de velo de novia, acercamiento de anillos, apoyo de lectura y micrófono).</p>
                                        </li>
                                        <li>
                                            <p>El día del evento acuden 2 o más asistentes según necesidad.</p>
                                        </li>
                                    </ul>
                                </li>
                                
                            </ul>
                        </div>


                    </div>
                    <div className="row gx-5">
                        <div className="col-lg-12 text-primary-2 text-justify">
                            <h4 className='text-uppercase mt-4'>Servicios Complementarios</h4>
                            <ul >
                                
                                <li><p>Amplificación de sonido.</p> </li>
                                <li><p>Reunión presencial de ensayo de ceremonia.</p> </li>
                                <li><p>Cronograma impreso del viaje ceremonial para invitados. Incluye, por ejemplo: canción de los novios, frases importantes de la ceremonia, etc.</p> </li>
                                <li><p>Ritual de meditación y preparación pre-boda.</p> </li>
                                <li><p>Cualquier rito agregado que no se incluya dentro del cuestionario de la ceremonia Elemental ( Ceremonia Estandar)</p> </li>
                            </ul>
                        </div>
                    </div>
                    <div className="row gx-5 mt-5">
                        <div className="col-lg-12 text-primary-2 text-justify font-weight-bold">
                            <p>(*) Las ceremonias realizadas fuera del anillo de Avenida Américo Vespucio y/u otras regiones se cobrará adicionalmente el costo de traslado.</p> 
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default ServiceDetailsPage;