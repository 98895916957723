import React, { Fragment, useEffect } from 'react';
import ReactGA from 'react-ga4' ;

const TeamPage = () => {
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "¿Quiénes Somos?" });
      }, []);
    return (
        <Fragment>
            <div className="section-title position-relative text-center mx-auto mb-3 mt-5 pb-3" style={{ maxWidth: '600px' }}>
                        {/* <h2 className="text-primary-2 font-secondary">Nosotras</h2> */}
                        <h1 className="display-4 text-uppercase font-title">¿Quiénes Somos?</h1>
                    </div>


            <div className="container-fluid py-5">
                <div className="container">
                    {/* <div className="section-title position-relative text-center mx-auto mb-5 pb-3" style={{maxWidth: '600px'}}>
                        <h2 className="text-primary font-secondary">Nuestro Equipo</h2>
                        <h1 className="display-4 text-uppercase">Our Master Chefs</h1> 
                    </div> */}
                    <div className="row g-5">

                        <div className="col-lg-12 col-md-12">
                            <div className="team-item1">
                                <div className="position-relative overflow-hidden">
                                    <img className="img-fluid w-100" src={process.env.PUBLIC_URL + '/imgs/equipo/grupal4.jpg'} alt="" />
                                    {/* <div className="team-overlay w-100 h-100 position-absolute top-50 start-50 translate-middle d-flex align-items-center justify-content-center">
                                            <div className="d-flex align-items-center justify-content-start">
                                            Bonita la suerte de venirnos a cruzar
                                            Bendita la palabra que se ha alzado por el pan
                                            Benditas compañeras de buenas revoluciones
                                            Benditos pensamientos que se volvieron canciones.
                                            </div>
                                        </div> */}
                                </div>
                                <div className="bg-dark-lavender border-inner text-white text-center p-4">
                                    {/* <h4 className=" text-primary">Marisol</h4> */}
                                    <p className=" m-0">Somos Marisol, Constanza y Zarina amigas entrañables, manada de lobas , tribu de mujeres compañeras de historia hace más de 20 años.</p>
                                    <p className=" m-0">Nuestra historia de amor ha estado teñida, sin duda, por la sinergia de nuestras energías y por el espíritu de celebración y su vivencia.</p>
                                    <p className=" m-0">Fue así que tras crear, organizar y celebrar la ceremonia de unión de una de nosotras, surge la idea y la necesidad de levantar un emprendimiento que nos permitiera profesionalizar nuestros 15 años de experiencia creando diversos rituales de celebración. </p>
                                    <p className=" m-0">Con Elemental queremos poner al servicio de las personas los distintos conocimientos, saberes y dones que cada una posee, los cuales combinados nos permitirán brindar ceremonias de unión emocionantes, inolvidables, mágicas y únicas.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
{/* 
            <div className="container-fluid bg-img py-5 mb-5">
                <div className="container py-5">
                    <div className="row gx-5 gy-4">
                        <div className="col-lg-12">

                            <p className='text-center'>
                                Bonita la suerte de venirnos a cruzar <br />
                                Bendita la palabra que se ha alzado por el pan<br />
                                Benditas compañeras de buenas revoluciones<br />
                                Benditos pensamientos que se volvieron canciones.
                            </p>

                        </div>
                    </div>
                </div>
            </div> */}

<div className="section-title position-relative text-center mx-auto mb-3 pb-3" style={{ maxWidth: '600px' }}>
                        {/* <h2 className="text-primary-2 font-secondary">Nosotras</h2> */}
                        <h1 className="display-4 text-uppercase font-title">EQUIPO</h1>
                    </div>

            <div className="container-fluid py-5">
                <div className="container">
                    {/* <div className="section-title position-relative text-center mx-auto mb-5 pb-3" style={{ maxWidth: '600px' }}>
                        <h1 className="display-4">Equipo</h1>
                    </div> */}
                    <div className="row g-5">
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="team-item">
                                <div className="position-relative overflow-hidden">
                                    <img className="img-fluid w-100" src={process.env.PUBLIC_URL + '/imgs/equipo/Solindividual.jpg'} alt="" />
                                    <div className="team-overlay w-100 h-100 position-absolute top-50 start-50 translate-middle d-flex align-items-center justify-content-center">
                                        <div className="d-flex align-items-center justify-content-start px-4 text-center text-white">
                                            Actriz de la Universidad de Chile y pedagoga teatral. Hace algunos años combina su pasión por las artes escénicas y el movimiento con estudios de astrología evolutiva, tarot y sabiduría uterina. En el año 2020 tiene su primera experiencia como sacerdotisa en una ceremonia de unión, lo que la ha llevado a desarrollar y estudiar el camino del rito y el poder de los cuatro elementos.
                                            {/* <a className="btn btn-lg btn-primary btn-lg-square rounded-1 mx-1" href="https://twitter.com/" target={'_blank'} rel='noreferrer'><i className="bi bi-twitter"></i></a>
                                                <a className="btn btn-lg btn-primary btn-lg-square rounded-1 mx-1" href="https://www.instagram.com/" target={'_blank'} rel='noreferrer'><i className="bi bi-instagram"></i></a>
                                                <a className="btn btn-lg btn-primary btn-lg-square rounded-1 mx-1" href="https://www.linkedin.com/" target={'_blank'} rel='noreferrer'><i className="bi bi-linkedin"></i></a> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-dark border-inner text-center p-4">
                                    <h4 className=" text-primary text-uppercase team-name">Marisol</h4>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-lg-4 col-sm-6">
                            <div className="team-item">
                                <div className="position-relative overflow-hidden">
                                    <img className="img-fluid w-100" src={process.env.PUBLIC_URL + '/imgs/equipo/Barbyindividual.jpg'} alt="" />
                                    <div className="team-overlay w-100 h-100 position-absolute top-50 start-50 translate-middle d-flex align-items-center justify-content-center" >
                                        <div className="d-flex align-items-center justify-content-start px-4 text-center text-white" >
                                            Ingeniera comercial de la Universidad Tecnológica de Chile, creadora de la tienda de ropa Rebravas e Hijas de la Barbarie. Posee una infinita curiosidad por el mundo espiritual y místico, lo que la ha llevado a ser parte de distintos talleres y círculo de mujeres donde ha podido trabajar en torno al conocimiento de la sabiduría femenina, la magia y el rito.
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-dark border-inner text-center p-4">
                                    <h4 className=" text-primary text-uppercase team-name">Bárbara</h4>
                                </div>
                            </div>
                        </div> */}
                        <div className="col-lg-4 col-md-6  col-sm-12">
                            <div className="team-item">
                                <div className="position-relative overflow-hidden">
                                    <img className="img-fluid w-100" src={process.env.PUBLIC_URL + '/imgs/equipo/Conyindividual.jpg'} alt="" />
                                    <div className="team-overlay w-100 h-100 position-absolute top-50 start-50 translate-middle d-flex align-items-center justify-content-center">
                                        <div className="d-flex align-items-center justify-content-start px-4 text-center text-white" >
                                            Enfermera de la Universidad Santo Tomás. Su inquietud y amor por el trabajo en torno a la sanación corporal y emocional de las personas la han hecho combinar sus saberes de enfermería con su pasión por el conocimiento de las plantas, su cuidado y sabiduría, tomando cursos de herbología y preparados medicinales. Actualmente se encuentra estudiando fitoterapia.
                                            {/* <a className="btn btn-lg btn-primary btn-lg-square rounded-1 mx-1" href="https://twitter.com/" target={'_blank'} rel='noreferrer'><i className="bi bi-twitter"></i></a>
                                                <a className="btn btn-lg btn-primary btn-lg-square rounded-1 mx-1" href="https://www.instagram.com/" target={'_blank'} rel='noreferrer'><i className="bi bi-instagram"></i></a>
                                                <a className="btn btn-lg btn-primary btn-lg-square rounded-1 mx-1" href="https://www.linkedin.com/" target={'_blank'} rel='noreferrer'><i className="bi bi-linkedin"></i></a> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-dark border-inner text-center p-4">
                                    <h4 className=" text-primary text-uppercase  team-name">Constanza</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 offset-md-3  offset-lg-0  col-sm-12">
                            <div className="team-item">
                                <div className="position-relative overflow-hidden">
                                    <img className="img-fluid w-100" src={process.env.PUBLIC_URL + '/imgs/equipo/Zariindividual.jpg'} alt="" />
                                    <div className="team-overlay w-100 h-100 position-absolute top-50 start-50 translate-middle d-flex align-items-center justify-content-center">
                                        <div className="d-flex align-items-center justify-content-start px-4 text-center text-white" >
                                            Actriz egresada de la Universidad de Chile y pedagoga teatral. Su inmensa curiosidad e intereses creativos no solo la han llevado a desarrollar el área de las artes teatrales, sino que también ha indagado en el trabajo del diseño gráfico y fotográfico. Hace algunos años se encuentra estudiando astrología evolutiva, especializándose en lecturas de cartas natales.
                                            {/* <a className="btn btn-lg btn-primary btn-lg-square rounded-1 mx-1" href="https://twitter.com/" target={'_blank'} rel='noreferrer'><i className="bi bi-twitter"></i></a>
                                                <a className="btn btn-lg btn-primary btn-lg-square rounded-1 mx-1" href="https://www.instagram.com/" target={'_blank'} rel='noreferrer'><i className="bi bi-instagram"></i></a>
                                                <a className="btn btn-lg btn-primary btn-lg-square rounded-1 mx-1" href="https://www.linkedin.com/" target={'_blank'} rel='noreferrer'><i className="bi bi-linkedin"></i></a> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-dark border-inner text-center p-4">
                                    <h4 className=" text-primary text-uppercase  team-name">Zarina</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default TeamPage;